/* eslint-disable no-undef */
export default () => {
	let timer = null;
	$('#prod-search').on('input', (evt) => {
		const field = $(evt.target);
		const query = field.val().trim();
		// Wait 500ms before sending request to server to avoid too many requests at once
		if (timer !== null) clearTimeout(timer);
		timer = setTimeout(() => {
			const action = field.closest('form').attr('action');
			const qs = new URLSearchParams(action);
			if (qs.has('search')) qs.delete('search');
			if (qs.has('page')) qs.delete('page');
			qs.set('search', query);
			qs.set('mode', 'preview');
			const url = Array.from(qs.entries()).map((e) => {
				const k = e[0]; const v = e[1];
				if (k.includes(window.location.host) && v.length === 0) {
					return `${k}?`;
				}
				return e.join('=');
			}).join('&');

			if (window.location.href !== url && window.history && window.history.pushState) {
				window.history.pushState({}, `${query} - Recherche`, url);
			}
			$('#search-view-all').attr('href', url);
			if (query.length < 3 && query.length > 0) return false;
			$.ajax({
				url,
				type: 'GET',
				dataType: 'html',
				beforeSend() {
					$('#psearch-icon').html('<i class="far fa-spinner-third fa-spin"></i>');
				},
				success(res) {
					const html = $(res).find('.search-wrapper').html();
					$('.search-wrapper').html(html);
				},
				error() {
					// eslint-disable-next-line no-alert
					alert("Une erreur s'est produite. Veuillez rafraîchir la page et réessayer.");
				},
				complete() {
					$('#psearch-icon').html('<i class="far fa-search"></i>');
				},
			});
			return true;
		}, 500);
	});
};
